<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-2"><b>Step 2.</b> <i>Isolating the unknown acid.</i></p>

      <!--image-->
      <p>
        -> (image placeholder) Filter and wash with cold
        <v-select
          v-model="inputs.input1"
          class="my-1"
          style="display: inline-block; width: 12em"
          outlined
          :items="options"
          item-text="text"
          item-value="value"
        >
          <template #item="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
          <template #selection="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
        </v-select>
        .
      </p>
      <p>-> Take a mass of your acid.</p>
      <p>
        ->
        <v-select
          v-model="inputs.input2"
          class="my-1"
          style="display: inline-block; width: 12em"
          outlined
          :items="options"
          item-text="text"
          item-value="value"
        >
          <template #item="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
          <template #selection="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
        </v-select>
        your unknown acid with the procedure you created in weeks 1 and 2.
      </p>
      <p>
        Take a

        <v-select
          v-model="inputs.input3"
          class="my-1"
          style="display: inline-block; width: 12em"
          outlined
          :items="options"
          item-text="text"
          item-value="value"
        >
          <template #item="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
          <template #selection="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
        </v-select>
        and perform a mixed melting point for identification.
      </p>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import STextarea from '@/common/components/STextarea.vue';
import {seededShuffle} from '@/courses/utils/seededShuffle';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex.vue';

export default {
  name: 'ChemUCI51LB_A1Q16d',
  components: {ChemicalLatex, StembleLatex, STextarea},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
        input2: null,
        input3: null,
      },
      options: [
        {text: 'melting point', value: 'meltingPoint'},
        {text: 'step2', value: 'step2'},
        {text: 'recrystallize', value: 'recrystallize'},
        {text: 'pH', value: 'pH'},
        {text: '~3mL', value: '3mL'},
        {text: 'step1', value: 'step1'},
        {text: '3 mL deionized (DI) water', value: 'deionizedWater'},
        {text: '6 M HCl', value: 'HCl'},
      ],
    };
  },
  computed: {
    seed() {
      return this.$inertia.getSeed();
    },
    optionsShuffled() {
      return seededShuffle(this.options, this.seed);
    },
  },
};
</script>
